<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <form ref="form" @submit.prevent="handleFileUpload" id="form-file" class="mx-6">
        <b-upload v-model="file" expanded
                  accept=".pdf,.png,.jpg,.jpeg,.gif,.tiff,.webp,.bmp"
                  class="file-label mt-4" name="file">
             <span class="button is-primary is-fullwidth">
                 <b-icon class="file-icon" icon="upload"></b-icon>
                 <span class="file-label">Click para subir receta médica</span>
             </span>
        </b-upload>
      </form>
      <div class="mt-4 mx-6">
         <span v-if="file">
                 {{ "Se ha seleccionado el archivo: " + file.name }}
         </span>
        <span v-if="file == null && fileURL == null">
           {{ message }}
         </span>
      </div>
      <div v-if="fileURL" class="mx-6" height="250px">
        <iframe :src="fileURL" width="100%" height="600px" v-if="type = 'application/pdf'"></iframe>
        <img :src="fileURL" width="70%" height="300px" v-else>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" form="form-file" type="submit">Guardar</button>
    </footer>
  </div>
</template>

<script>

export default {
  name: "ModalGeneral",
  data() {
    return {
      file: null,
      fileURL: null,
      type: null,
      message: null
    }
  },
  props: {
    title: String,
    noDataMessage: String,
    code: String,
  },
  created() {
    this.file = null;
    this.getMedicalRecipe(this.code);
  },
  methods: {
    updateComment(event) {
      // Emitimos el evento 'update' para actualizar el valor del padre
      this.$emit('update', event);
    },
    executeAction() {
      if (this.action) {
        this.action(); // Llamamos la función pasada por el padre
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString(); // Formato legible de la fecha
    },
    handleFileUpload(event) {
      if (!this.file) {
        this.$buefy.dialog.alert({
          title: 'Error',
          type: 'is-danger',
          message: 'Debes seleccionar un archivo antes de subirlo'
        });
        return;
      }

      const formData = new FormData(event.target);
      formData.append("file", this.file);
      this.$loader.show();
      this.$http.post(`/callCenter/medicalRecipeFile/${this.code}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => {
        //this.$parent.close();
        this.$buefy.dialog.alert({
          title: 'Procesado con éxito',
          type: 'is-success',
          hasIcon:true,
          icon:'',
          message: 'Receta almacenada con éxito'
        });
        this.$emit('success');
        this.getMedicalRecipe(this.code)
        this.file = null;
      }).catch(error => {
        this.$buefy.dialog.alert({
          title: "Error",
          type: "is-danger",
          message: "Hubo un problema al subir el archivo",
        });
      }).finally(() => {
        this.$loader.hide();
      });
    },
    getMedicalRecipe(code) {
      this.$http.get(`/callCenter/medicalRecipeFileDownload/${code}`, { responseType: "blob" })
          .then(response => {
            const fileResponse = new Blob([response.data], { type: response.data.type });
            this.fileURL = URL.createObjectURL(fileResponse); // Asigna la URL al iframe
            this.type = response.data.type;
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.message = this.noDataMessage;
            } else {
              this.$buefy.dialog.alert({
                title: "Error",
                type: "is-danger",
                message: "Hubo un problema al obtener el archivo",
              });
            }
          });
    }
  }
}
</script>

<style scoped>

</style>