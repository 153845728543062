<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Comentarios" label-position="on-border">
        <b-input
            type="textarea"
            v-model="computedComment"
            @input="$emit('update:modelValue', $event)"
        ></b-input>
      </b-field>
      <b-table
          :data="data.data"
          searchable
          paginated
          per-page="10"
          :backend-filtering="true"
          pagination-simple
          :current-page="data != '' ? parseInt(data.current_page) : parseInt(1)"
          :total="data != '' ? parseInt(data.total): 0"
          backend-pagination
          @page-change="$emit('page-changed', $event)">
        <b-table-column field="fechaCreacion" label="Fecha" v-slot="props">
          {{ formatDate(props.row.fechaCreacion) }}
        </b-table-column>
        <b-table-column field="comentario" label="Comentario" v-slot="props" width="70%">
          {{ props.row.comentario }}
        </b-table-column>
        <b-table-column field="creado por:" label="Creado por:" v-slot="props">
          {{ props.row.user.nombre }}
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                    icon="frown"
                    size="is-large">
                </b-icon>
              </p>
              <p>Lo sentimos no hay ningun comentario en esta venta por teléfono.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" type="button" @click="executeAction">Guardar</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalGeneral",
  props: {
    title: String,
    noDataMessage: String,
    modelValue: String,
    action: Function,
    data: Array,
  },
  computed: {
    computedComment: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    updateComment(event) {
      // Emitimos el evento 'update' para actualizar el valor del padre
      this.$emit('update', event);
    },
    executeAction() {
      if (this.action) {
        this.action(); // Llamamos la función pasada por el padre
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString(); // Formato legible de la fecha
    }
  },
}
</script>

<style scoped>

</style>