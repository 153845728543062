<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Ventas Call Center</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Nueva">
            <b-button icon-left="file" size="is-large" type="is-primary" @click="clearSalesCallCenter"></b-button>
          </b-tooltip>
          <b-tooltip label="Ver PDF de cotización" v-if="canSendQuotation">
            <b-button icon-left="eye" size="is-large" @click="showPDF = true"></b-button>
          </b-tooltip>
          <b-tooltip label="Ver PDF de orden" v-if="canSendOrder">
            <b-button icon-left="eye" size="is-large" @click="showPDF = true"></b-button>
          </b-tooltip>
          <b-tooltip label="Guardar" v-if="$alfalab.callCenterAccess(this)">
            <b-button icon-left="save" size="is-large" @click="onSave" :disabled="readOnly"></b-button>
          </b-tooltip>
          <b-tooltip label="Enviar Cotización" v-if="canSendQuotation">
            <b-button icon-left="envelope" size="is-large" @click="onSendQuotation"></b-button>
          </b-tooltip>
          <b-tooltip label="Enviar PDF de orden" v-if="canSendOrder">
            <b-button icon-left="envelope" size="is-large" @click="onSendOrder"></b-button>
          </b-tooltip>
          <b-tooltip label="Buscar">
            <b-button icon-left="search" size="is-large" @click="getData"></b-button>
          </b-tooltip>
          <b-tooltip label="Generar Orden"
                     v-if="false && $alfalab.isAdmin($auth.user())"
          >
            <b-button icon-left="laptop-medical" size="is-large" @click="createOrder" :disabled="has_orders"></b-button>
          </b-tooltip>
          <b-tooltip label="Registrar pago"
                     v-if="canMakePayment"
          >
            <b-button icon-left="fas fa-upload" size="is-large"
                      @click="showRegisterPayment=true"></b-button>
          </b-tooltip>
          <b-tooltip label="Ver Comprobante de Pago" v-if="canViewPayment">
            <b-button icon-left="file-invoice-dollar" size="is-large" @click="showPaymentDocument = true"></b-button>
          </b-tooltip>
          <b-tooltip label="Comentarios" v-if="canSendQuotation">
            <b-button icon-left="commenting" size="is-large" @click="getCommentRecipe"></b-button>
          </b-tooltip>
          <b-tooltip label="Receta" v-if="canSendQuotation">
            <b-button icon-left="file-text" size="is-large" @click="getMedicalRecipe"></b-button>
          </b-tooltip>
          <div v-if="readOnly" style="width: 100%">
            <b-field label="Forma de Pago">
              <b-select v-model="payment_type">
                <option value="IVR">IVR</option>
                <option value="LINK">LINK DE PAGO</option>
              </b-select>
            </b-field>
            <p>Estado: {{ statusFormatted }}</p>
            <a :href="url + payment_type" target="_blank">URL: {{ url }}{{ payment_type }}</a>
          </div>
          <b-tooltip
              v-if="patient && patient.lapifan_active && patient.lapifan_active.status"

              label="Lapifan">
            <b-button
                @click="handleLapifanClick"
                size="is-large"
                style="color: gold"
                icon-left="crown"></b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="column is-12 is-7-desktop is-8-widescreen is-9-fullhd has-background-white is-multiline">
        <div class="columns is-12 column  is-multiline">
          <div class="column is-4">
            <b-field label="Fecha de venta"
                     label-position="on-border">
              <b-input v-model="date" :disabled="true"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Folio"
                     label-position="on-border">
              <b-input v-model="code" :disabled="true"/>
            </b-field>
          </div>
          <div class="column is-12" v-if="! hasPatient">
            <select-patient class="columns"
                            type="salescallcenter"
                            :patient.sync="patient"
                            @select="handleChangePatient"
            ></select-patient>
          </div>
          <div class="column is-12">
            <SelectMedical
                @update:medical="handleUpdateMedical"
                :disabled="readOnly"
                :medical="medical"
                type="search"/>
          </div>
          <div  class="column is-11">
            <b-field label="¿Cómo nos conocieron?"
                     label-position="on-border">
              <select-how-we-were-known :searchable="false"
                                        :data="howWeWereKnownOptions"
                                        v-model="selectedHowWeWereKnown"
                                        :disabled="readOnly"
                                        :readonly="readOnly"
                                        autofocus
              >
              </select-how-we-were-known>
            </b-field>
          </div>
          <div class="column is-12" v-if="code === ''">
            <b-field label="Comentarios" label-position="on-border">
              <b-input
                  v-model="comentario"
                  type="textarea"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="column is-12">
          <b-field grouped group-multiline>
            <p class="control">
              <button class="button field is-danger"
                      @click="clearStudies"
                      :disabled="!checkedRows.length">
                <b-icon icon="eraser"></b-icon>
              </button>
            </p>
            <p class="control">
              <button :disabled="!checkedRows.length"
                      class="button"
                      @click="onDeleteItem">
                <b-icon icon="trash"></b-icon>
              </button>
            </p>
            <p class="control">
              <select-study @selectStudy="onSelectStudy" v-if="!readOnly"></select-study>
            </p>
          </b-field>
        </div>
        <div class="column ">
          <b-table
              :checkable="true"
              :checked-rows.sync="checkedRows"
              :data="studies">
            <b-table-column v-slot="props" label="Promoción" field="companyName">{{
                props.row.companyName
              }}
            </b-table-column>
            <b-table-column v-slot="props" label="Código" field="studyCode">{{ props.row.studyCode }}</b-table-column>
            <b-table-column v-slot="props" label="Estudio" field="studyName">
              <a @click="handleShowDetail(props.row.studyCode)">{{ props.row.studyName }}</a>
            </b-table-column>
            <b-table-column v-slot="props" label="Precio Sucursal" field="price">
              {{ (props.row.price_base * 1.16).toFixed(2) }}
            </b-table-column>
            <b-table-column v-slot="props" label="Precio" field="price">
              <span :class="discount && !['LAPIFAN'].includes(props.row.studyCode) ? 'has-discount' : ''">{{
                  (props.row.price * 1.16).toFixed(2)
                }}</span>
              <br>
              <span v-if="discount && !['LAPIFAN'].includes(props.row.studyCode)">{{
                  (props.row.price * 1.16 - (props.row.price * 1.16 * lapifanDiscount)).toFixed(2)
                }}</span>
            </b-table-column>
            <b-table-column v-slot="props" label="Indicaciones" field="instructions">{{
                props.row.instructions
              }}
            </b-table-column>
            <b-table-column v-slot="props" label="D.Entrega" field="deliveryDays">{{
                props.row.delivery_days
              }}
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                        icon="box-open"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>No hay estudios seleccionados.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>

      <div class="column is-5-desktop is-4-widescreen is-3-fullhd has-background-white"
           style="border-left: 2px #dbdbdb solid">
        <div style="">
          <div class="column is-12">
            <div class="field is-size-6" style="margin-bottom: 1em">
              <template v-if="hasPatient">
                <div class="is-divider" data-content="Paciente"></div>
                <data-sales-call-center
                    :data="patient"
                    :read-only="hasId"
                    :allow-edit="!hasId"
                ></data-sales-call-center>
              </template>
              <div class="is-divider" data-content="Importe"></div>
              <div v-if="!!!code">
                <span class="is-pulled-left">Subtotal: </span>
                <span class="is-pulled-right">{{ subTotal | currency }}</span> <br>

                <b-checkbox v-model="tax" :disabled="canSendOrder">
                  IVA:
                </b-checkbox>
                <span v-if="tax" class="is-pulled-right">{{ taxes | currency }}</span>
                <span v-else class="is-pulled-right">$0.00</span>
                <br>
                <span class="is-pulled-right"></span>
                <span class="is-pulled-right"></span> <br>
                <div v-if="showLapifanDiscount && !canViewPayment">
                  <b-checkbox v-model="discount">
                    Aplicar descuento 5%
                  </b-checkbox>
                  <span v-if="discount" class="is-pulled-right">{{ discounts | currency }}</span>
                  <span v-else class="is-pulled-right">$0.00</span>
                </div>

                <br>
                <span class="is-pulled-right"></span>
                <span class="is-pulled-right"></span> <br>
                <span class="is-pulled-left has-text-weight-bold">Total: </span>
                <span class="is-pulled-right">{{ Math.ceil(total) | currency }}</span> <br>
              </div>
              <div v-else>
                <span class="is-pulled-left has-text-weight-bold">Total: </span>
                <span class="is-pulled-right">{{ totalSaved | currency }}</span> <br>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe"
              :src="`${urlApi}/phone-sell/preview/${code}?${this.status === 'PENDIENTE' ? 'quotation': ''}`"></iframe>
    </b-modal>
    <b-modal v-model="showLapifan" v-if="patient && patient.lapifan_active && patient.lapifan_active.status">
      <lapifan-modal :patient="patient"/>
    </b-modal>
    <b-modal v-model="showListSalesCallCenter" has-modal-card full-screen>
      <modal-select-sale :data-sales="dataSales"
                         @search="onModalSearch"
                         @page-change="onPageChange"
                         @select="onSelect"></modal-select-sale>
    </b-modal>
    <b-modal v-model="showRegisterPayment" has-modal-card>
      <modal-register-payment :code="code" @success="status='APROBADO'"></modal-register-payment>
    </b-modal>
    <b-modal v-model="showPaymentDocument" @click="showPaymentDocument=true">
      <iframe class="lapi__iframe" :src="file_path"></iframe>
    </b-modal>
    <b-modal v-model="showModalCommentCallCenter" has-modal-card full-screen>
      <comments-recipe
          :title=title
          :noDataMessage="noDataMessage"
          :modelValue="commentRecipe"
          :action="saveComment"
          @update:modelValue="commentRecipe = $event"
          :data="comments"
          @page-changed="getComments"></comments-recipe>
    </b-modal>
    <b-modal v-model="showModalMedicalRecipe" has-modal-card full-screen>
      <medical-recipe
          :title=title
          :noDataMessage="noDataMessage"
          :code="this.code"></medical-recipe>
    </b-modal>
  </div>
</template>

<script>
import SelectPatient from "@/components/patient/SelectPatient";
import SelectStudy from "@/components/sales-call-center/SelectStudy";
import LapifanModal from "../../components/lapifan/LapifanModal.vue";
import {mapState, mapGetters} from "vuex";
import salescallcenter from "@/store/modules/salescallcenter";
import ListSalesCallCenter from "@/components/sales-call-center/ModalSelectSale";
import ModalSelectSale from "@/components/sales-call-center/ModalSelectSale";
import DataSalesCallCenter from "@/components/sales-call-center/DataSalesCallCenter";
import ModalRegisterPayment from "@/components/sales-call-center/ModalRegisterPayment";
import ModalStudyDetail from "../../components/study/ModalStudyDetail.vue";
import CommentsRecipe from "@/components/sales-call-center/CommentsRecipe";
import MedicalRecipe from "@/components/sales-call-center/MedicalRecipe";
import SelectHowWeWereKnown from "./SelectHowWeWereKnown.vue";
import SelectMedical from "../../components/medical/SelectMedical.vue";

export default {
  name: "Index",
  components: {
    DataSalesCallCenter,
    ModalSelectSale,
    LapifanModal,
    SelectPatient,
    SelectStudy,
    ListSalesCallCenter,
    ModalRegisterPayment,
    ModalStudyDetail,
    CommentsRecipe,
    MedicalRecipe,
    SelectHowWeWereKnown,
    SelectMedical
  },
  props: {
    data: Array,

  },
  data() {
    return {
      checkedRows: [],
      showPDF: false,
      showLapifan: false,
      showListSalesCallCenter: false,
      studies: [],
      tax: true,
      code: '',
      status: '',
      readOnly: false,
      dataSales: {},
      search: '',
      query: {},
      date: '',
      url: '',
      has_orders: false,
      file: null,
      fileName: '',
      has_code: false,
      showPaymentDocument: false,
      showRegisterPayment: false,
      discount: false,
      file_path: '',
      can_payment: false,
      payment_type: 'IVR',
      totalSaved: 0,
      showModalCommentCallCenter: false,
      title: '',
      noDataMessage: '',
      commentRecipe: "",
      comments: [],
      showModalMedicalRecipe: false,
      currentPage: 1,
      totalComments: 0,
      selectedHowWeWereKnown: null,
      howWeWereKnownOptions: [],
      medical: {},
      comentario: ''
    }
  },
  computed: {
    //...mapState('salescallcenter',['studies']),
    ...mapState({
      methods: state => state.main.paymentTypes
    }),
    ...mapGetters('salescallcenter', ['hasPatient', 'hasId']),
    patient: {
      set(value) {
        this.$store.dispatch('salescallcenter/setPatient', value);
      },
      get() {
        return this.$store.state.salescallcenter.patient;
      }
    },
    subTotal() {
      const discount = this.discounts;
      const applyDiscount = this.discount;
      if (applyDiscount) {
        return this.studies.reduce((acc, item) => acc + item.price, 0) - discount;
      }
      return this.studies.reduce((acc, item) => acc + item.price, 0)
    },
    taxes() {
      const tax = .16;
      const discount = this.discounts;
      const applyDiscount = this.discount;
      if (applyDiscount) {
        const temp = this.studies.reduce((acc, item) => acc + item.price, 0) - discount;
        return parseFloat(temp * tax).toFixed(2);
      }
      return parseFloat(this.studies.reduce((acc, item) => acc + item.price, 0) * tax).toFixed(2);
    },
    totalWithTax() {
      const tax = 1.16;
      const discount = this.discounts;
      const applyDiscount = this.discount;
      if (applyDiscount) {
        const temp = this.studies.reduce((acc, item) => acc + item.price, 0) - discount;
        return parseFloat(temp * tax).toFixed(2);
      }
      return parseFloat(this.studies.reduce((acc, item) => acc + item.price, 0) * tax).toFixed(2);
    },
    total() {
      const tax = this.tax ? 1.16 : 1;
      const discount = this.discounts;
      const applyDiscount = this.discount;
      if (applyDiscount) {
        const temp = this.studies.reduce((acc, item) => {
          return acc + (['LAPIFAN'].includes(item.studyCode) ? item.price : (item.price - (item.price * this.lapifanDiscount)))
        }, 0);
        return parseFloat((temp * tax)).toFixed(2);
      }
      return parseFloat(this.studies.reduce((acc, item) => acc + item.price, 0) * tax).toFixed(2);
    },
    statusFormatted() {
      const values = {PENDIENTE: 'EN ESPERA DE PAGO', APROBADO: 'APROBADO', RECHAZADO: 'RECHAZADO'};
      return values[this.status] ?? 'NO DEFINIDO'
    },
    urlApi() {
      return process.env.VUE_APP_API_URL;
    },
    discounts() {
      const discount = (this.$auth.user().callcenter_lapifan_discount / 100);
      //console.log('discount:', discount)
      const total = this.studies.reduce((acc, item) => {
        return acc + (['LAPIFAN'].includes(item.studyCode) ? 0 : item.price * discount);
      }, 0)
      return parseFloat(total * 1.16).toFixed(2);
    },
    patientHasLapifan() {
      return this.patient && this.patient.lapifan_active && this.patient.lapifan_active.status;
    },
    listHasLapifan() {
      return this.studies.some(item => ['LAPIFAN'].includes(item.studyCode));
    },
    showLapifanDiscount() {
      // el paciente tiene lapifan
      // la lista tiene lapifan y otros estudios
      return this.patientHasLapifan || (this.listHasLapifan && this.studies.length > 1);
    },
    canMakePayment() {
      return !!this.code && this.status === 'PENDIENTE' && this.can_payment;
    },
    canViewPayment() {
      return !!this.code && this.status === 'APROBADO';
    },
    canSendQuotation() {
      return !!this.code && this.status === 'PENDIENTE';
    },
    canSendOrder() {
      return this.canViewPayment
    },
    lapifanDiscount() {
      return this.$auth.user().callcenter_lapifan_discount / 100
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    this.patient = {};
    const date = new Date();
    this.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.fecthHowWeWereKnown();
  },
  methods: {
    clearSalesCallCenter() {
      this.$loader.show();
      this.studies = [];
      this.readOnly = false;
      this.code = '';
      this.byCode = false;
      this.has_orders = false;
      this.status = '';
      this.patient = {};
      this.has_code = false;
      this.discount = false;
      this.can_payment = false;
      this.totalSaved = 0;
      this.medical = {};
      this.comentario = '';
      this.selectedHowWeWereKnown = null;
      const date = new Date();
      this.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      this.$store.dispatch('salescallcenter/clearSalesCallCenter').finally(() => {
        this.$loader.hide();
      })
    },
    handleLapifanClick() {
      this.showLapifan = true;
    },
    handleListSalesCallCenter() {
      this.showListSalesCallCenter = true;
    },
    async onSelectStudy(data) {
      this.$loader.show();
      const params = {
        study: data.studyCode,
        cart: this.studies.map(item => {
          return item.studyCode;
        })
      }
      let canAdd = true
      await this.$http.post('/cart/can-add', params).then(({data}) => {
        if (!data.status) {
          canAdd = false
          this.$buefy.dialog.alert({
            title: 'Error',
            message: `No es posible agregar el estudio seleccionado porque ya forma parte de algún elemento de la lista: [${data.studyItem}]`,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          });
        }
      }).finally(() => {
        this.$loader.hide();
      })
      if (!canAdd) {
        return;
      }
      this.studies.push(data);
      this.tax = !data.noTax;
      if (!this.tax) {
        //  console.log('sin iva');
        this.$buefy.dialog.confirm({
          title: 'Estudio sin IVA',
          message: 'Atención este estudio no contiene IVA',
          confirmText: 'Aceptar',
          cancelText: 'Cerrar'
        });
      }
      this.studies = [...this.studies];
      //console.log('index:',this.studies)
    },
    clearStudies() {
      this.studies = [];
    },
    onDeleteItem() {
      this.studies = this.studies.filter(study => !this.checkedRows.includes(study));
      this.checkedRows = [];
    },
    onSave() {
      const params = Object.assign({}, {
        patient: {code: this.patient.code},
        studies: this.studies.map(study => {
          return {
            code: study.studyCode,
            tax: this.tax ? 16 : 0,
            company: study.companyCode,
          }
        }),
        lapifan_discount: this.discount,
        cat_how_we_were_known_id: this.selectedHowWeWereKnown,
        medical: this.medical && !!this.medical.code ? this.medical.code : '',
        comment: this.comentario
      });
      if (this.patient && this.patient.lapifan_active && this.patient.lapifan_active.status) {
        this.loading = true;
        this.$buefy.dialog.confirm({
          title: 'Guardar Venta',
          message: '¿Está seguro de guardar? Recuerde que después de guardar no puede realizar modificaciones',
          confirmText: 'Si, guardar',
          cancelText: 'No, cancelar',
          onConfirm: () => {
            this.$loader.show();
            this.$http.post(`/phone-sell`, params).then(({data}) => {
              // console.log(data);
              this.$toast.success('Guardado correctamente');
              this.readOnly = true;
              this.code = data.data.code;
              this.status = data.data.status;
              this.url = data.data.url;
              this.totalSaved = data.data.amount;
              this.can_payment = data.can_payment;
              if(data.data.medical){
                this.medical = data.data.medical;
              }
              this.getComments();
            }).catch(errors => {
              this.$alfalab.showError(errors);
            }).finally(() => {
              this.$loader.hide();
            })
          }
        })
      } else if (this.patient || !this.patient.lapifan_active || !this.patient.lapifan_active.status) {
        const hasLapiFan = this.studies.some(s => s.studyCode === 'LAPIFAN');
        const requireLapiFan = this.studies.some(study => study.requireLapifan);
        //console.log('lapifan', hasLapiFan, requireLapiFan)
        if (hasLapiFan) {
          //console.log('los estudios contienen lapifan');
          this.loading = true;
          this.$buefy.dialog.confirm({
            title: 'Guardar Venta',
            message: '¿Está seguro de guardar? Recuerde que después de guardar no puede realizar modificaciones',
            confirmText: 'Si, guardar',
            cancelText: 'No, cancelar',
            onConfirm: () => {
              this.$loader.show();
              this.$http.post(`/phone-sell`, params).then(({data}) => {
                //console.log(data);
                this.$toast.success('Guardado correctamente');
                this.readOnly = true;
                this.code = data.data.code;
                this.totalSaved = data.data.amount;
                this.status = data.data.status
                this.url = data.data.url;
                this.can_payment = data.can_payment;
                if(data.data.medical){
                  this.medical = data.data.medical;
                }
                this.getComments();
              }).catch(errors => {
                this.$alfalab.showError(errors);
              }).finally(() => {
                this.$loader.hide();
              })
            }
          })

        } else {


          //console.log(requireLapiFan);
          if (requireLapiFan) {
            //console.log('los estudios no tienen lapifan')
            this.loading = true;
            this.$buefy.dialog.confirm({
              title: 'Paciente sin lapifan',
              message: 'El paciente seleccionado no cuenta con lapifan, no se puede aplicar promoción' +
                  ' Favor de verificar.',
              confirmText: 'Aceptar',
              cancelText: 'Cerrar',
            });
            this.$loader.hide();
          } else if (!requireLapiFan) {
            this.loading = true;
            this.$buefy.dialog.confirm({
              title: 'Guardar Venta',
              message: '¿Está seguro de guardar? Recuerde que después de guardar no puede realizar modificaciones',
              confirmText: 'Si, guardar',
              cancelText: 'No, cancelar',
              onConfirm: () => {
                this.$loader.show();
                this.$http.post(`/phone-sell`, params).then(({data}) => {
                  //console.log(data);
                  this.$toast.success('Guardado correctamente');
                  this.readOnly = true;
                  this.code = data.data.code;
                  this.status = data.data.status
                  this.totalSaved = data.data.amount;
                  this.url = data.data.url;
                  this.can_payment = data.can_payment;
                  if(data.data.medical){
                    this.medical = data.data.medical;
                  }
                  this.getComments();
                }).catch(errors => {
                  this.$alfalab.showError(errors);
                }).finally(() => {
                  this.$loader.hide();
                })
              }
            })
          }

        }
      }
    },
    getData() {
      this.$loader.show();
      const params = {
        ...this.query,
        page: this.page,
        perPage: 20,
      };
      this.$http.get(`/phone-sell`, {params}).then(async ({data}) => {
        this.dataSales = data;
        this.handleModalSelectSale();
      }).catch(errors => {

      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    handleModalSelectSale() {
      this.showListSalesCallCenter = true;
    },
    async onModalSearch(query) {
      //console.log('query', query)
      this.query = query;
      this.page = 1;
      await this.getData();
    },
    onSelect(selectedValue) {
      this.$loader.show();
      this.$http.get(`/phone-sell/${selectedValue.code}`).then(async ({data}) => {
        //console.log(data, 'data');

        const phoneSell = data.data;
        this.code = phoneSell.code;
        this.studies = phoneSell.studies;
        this.date = phoneSell.date;
        this.readOnly = true;
        this.tax = !phoneSell.noTax;
        this.url = phoneSell.url;
        this.status = phoneSell.status;
        this.has_orders = phoneSell.has_orders;
        this.has_code = phoneSell.has_code;
        this.file_path = phoneSell.file_path;
        this.can_payment = data.can_payment;
        this.totalSaved = phoneSell.amount;
        this.selectedHowWeWereKnown = phoneSell.how_we_were_known_id;
        if(phoneSell.medical){
          this.medical = phoneSell.medical;
        }
        //this.patient = phoneSell.patient;
        this.$store.dispatch("salescallcenter/setPatient", phoneSell.patient);
        this.getComments();
      }).catch(errors => {
      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    onPageChange(page) {
      this.page = page;
      this.both = true;
      this.getData();
    },
    createOrder() {
      const params = Object.assign({}, {
        code: this.code
      });
      this.loading = true;
      this.$buefy.dialog.confirm({
        title: 'Generar Orden',
        message: '¿Está seguro de generar la orden?',
        confirmText: 'Si, generar orden',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.post(`/phone-sell-by-admin`, params).then(({data}) => {
            this.$toast.success('Orden creada correctamente');
            this.readOnly = true;
            this.code = data.code;
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
          })
        }
      });
    },
    handleRegistryPayment() {
      this.$refs.fileInput.click();
    },
    onSendQuotation() {
      this.$loader.show();
      this.$http.post(`/phone-sell/${this.code}/quotation`).then(({data}) => {
        this.$toast.success('Cotización enviada con éxito');
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSendOrder() {
      this.$loader.show();
      this.$http.post(`/phone-sell/${this.code}/notify`).then(({data}) => {
        this.$toast.success('Cotización enviada con éxito');
      }).finally(() => {
        this.$loader.hide();
      });
    },
    handleChangePatient() {
      this.discount = false;
    },
    handleShowDetail(code) {
      //console.log('code', code);
      this.$buefy.modal.open({
        parent: this,
        component: ModalStudyDetail,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          code
        }
      })
    },
    getCommentRecipe() {
      this.$loader.show();
      this.title = 'Comentarios de venta por teléfono';
      this.noDataMessage = 'No hay comentarios para esta venta por teléfono';
      this.showModalCommentCallCenter = true;
      this.$loader.hide();
    },
    getMedicalRecipe() {
      this.$loader.show();
      this.title = 'Receta Médica';
      this.noDataMessage = 'No hay receta médica';
      this.showModalMedicalRecipe = true;
      this.$loader.hide();
    },
    updateComment(newComment) {
      this.comment = newComment;  // Actualizamos el valor del comentario en el padre
    },
    saveComment(){
      this.$loader.show();
      const params = Object.assign({}, {
        code: this.code,
        comment: this.commentRecipe,
      });
      this.$http.post('/comment-sale-phone', params).then(({data}) => {
        if (data.success == true) {
          this.getComments();
          this.$toast.success('Comentario guardado correctamente');
          this.commentRecipe = '';
        } else {
          this.$toast.error('Error al guardar el comentario');

        }
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    getComments(page = 1) {
      this.$loader.show();
      this.$http.get(`/comments/${this.code}?page=${page}`).then(({data}) => {
        this.comments = data.data;

      }).finally(() => {
        this.$loader.hide();
      });
    },
    fecthHowWeWereKnown() {
      this.$http.get("/phone-cell-cat-how-we-were-known") // 🔹 Cambia la URL según tu API
          .then(response => {
            this.howWeWereKnownOptions = response.data; // 🔹 Guarda los datos en el array
          })
          .catch(error => {
            console.error("Error al obtener opciones:", error);
          });
    },
    handleUpdateMedical(el){
      //console.log('handleUpdateMedical', el)
      this.medical = el;
    }
  }
}
</script>

<style scoped>
.lapi__iframe {
  width: 100%;
  height: 80vh;
}

.has-discount {
  color: red;
  text-decoration: line-through;
}
</style>