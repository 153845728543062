
<template>
  <b-field
      :type="error ? 'is-danger' : ''"
      :message="error">
    <template v-if="searchable">
      <b-autocomplete :readonly="readonly"
                      open-on-focus
                      field="nombre"
                      expanded
                      class="select-branch-office"
                      :loading="loading"
                      :disabled="disabled"
                      :data="filteredDataArray"
                      :autofocus="autofocus"
                      :clearable="!disabled"
                      @select="option => $emit('input', option[optionValue])"
                      ref="SelectHowWeWereKnown"
                      v-model="nombre"
                      placeholder="¿Cómo nos conocieron?">
      </b-autocomplete>
    </template>
    <b-select v-else :value="value"
              @input="val => $emit('input', val)"
              :readonly="readonly"
              :disabled="disabled"
              :autofocus="autofocus"
              ref="SelectHowWeWereKnown"
              expanded
    >
      <option v-for="item in data"
              :value="item[optionValue]">
        {{ item[optionText] }}
      </option>
    </b-select>
  </b-field>
</template>

<script>

export default {
  name: "SelectHowWeWereKnown",
  props: {
    readonly: Boolean,
    disabled: Boolean,
    value: {},
    searchable: Boolean,
    autofocus: Boolean,
    optionText: {
      type: String,
      default: 'nombre'
    },
    optionValue: {
      type: String,
      default: 'id'
    },
    loading: Boolean,
    error: String|Array,
    data: {
      type: Array,
      /* default() {
        return this.$store.state.main.accountBranchOffices
      } */
    },
  },
  data() {
    return {
      nombre: ''
    }
  },
  watch: {
    value(val) {
      console.log('Watch value', val);
      if(val) {
        if (this.data.length > 0) {
          const found = this.data.find(el => el.id == val);
          if(found)
            this.nombre = found.nombre;
          else
            this.nombre = '';
        }
      } else {
        this.nombre = '';
      }
    }
  },
  computed: {
    filteredDataArray() {
      return this.data.filter((option) => {
//        console.log(option, 'option');

        return option.nombre
            .toString()
            .toLowerCase()
            .indexOf(this.nombre.toLowerCase()) >= 0
      })
    }
  }
}
</script>

<style lang="scss">
.select-branch-office {
  .dropdown-menu {
    //position:relative;
  }
}

</style>
